<template>
<navbar-component @changeLanguage="changeLanguage" />
<router-view />
<footer-component />
</template>

<script>
import FooterComponent from "@/components/shared/FooterComponent.vue";
import NavbarComponent from "@/components/shared/NavbarComponent.vue";
import
{
    setCurrentLanguaje
}
from './utils/lang/current';

export default
{
    components:
    {
        "navbar-component": NavbarComponent,
        "footer-component": FooterComponent,
    },
    methods:
    {
        changeLanguage(lang)
        {
            setCurrentLanguaje(lang);
            this.$router.go();
        },
    },
}
</script>

<style>

</style>
