export default
    {
        filosofia:
        {
            info: "Conserflow es una empresa fundada en 2005 creada con el objetivo de ofrecer un servicio y atencion de excelencia en los diferentes proyectos de ingeniería.",
            politica: "POLÍTICA DE INTEGRAL",
            politica_text: "En CONSERFLOW proporcionamos productos y servicios relacionados con soluciones tecnológicas en el área de la industria del proceso, mecánica, eléctrica, civil, de instrumentación, automatización y control que cumplen los requisitos de nuestros clientes, legales y normativos aplicables en materia de calidad, seguridad, medio ambiente y salud, para ello implementamos una cultura organizacional comprometida con la mejora continua, la satisfacción de nuestras partes interesadas, la consciencia ambiental y el cuidado de los recursos naturales.",
            mision: "MISIÓN",
            mision_text: "Proporcionar productos y servicios relacionados con soluciones tecnológicas que satisfacen y superan las expectativas de nuestros clientes a través de la mejora continua y el desarrollo sustentable.",
            vision: "VISIÓN",
            vision_text: "Ser vistos como un referente de soluciones tecnológicas en el área de la industria del proceso, mecánica, eléctrica, civil, de instrumentación, automatización y control manteniendo un alto nivel de calidad y confianza con nuestros clientes.",
        },
        certificaciones:
        {
            certificaciones: "CERTIFICACIONES"

        },
        clientes:
        {
            clientes:"NUESTROS CLIENTES"
        },
        alianzas:
        {
            alianzas:"ALIANZAS CON MARCAS LÍDERES"
        }
    }