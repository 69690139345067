<template>
<footer class="ftco-footer ftco-section services-section img-footer" style="background-image: url(images/footer.webp);">
    <div class="container">
        <div class="row mb-5">
            <div class="col-md">
                <div class="ftco-footer-widget mb-4">
                    <a class="navbar-brand footer-logo" style="background-image: url('images/conserflow.webp');">
                    </a>
                </div>
            </div>
            <div class="col-md">
                <div class="ftco-footer-widget mb-4 ml-md-5">
                    <router-link :to="{name:'products'}" class="ftco-heading-2">
                        <h2>{{message("productos/productos")}}</h2>
                    </router-link>
                    <ul class="list-unstyled">
                        <li>
                            <p>{{message("productos/calibracion")}}</p>
                        </li>
                        <li>
                            <p>{{message("productos/automatizacion")}}</p>
                        </li>
                        <li>
                            <p>{{message("productos/instrumentacion")}}</p>
                        </li>
                        <li>
                            <p>{{message("productos/fabricacion")}}</p>
                        </li>
                        <li>
                            <p>{{message("productos/gama_productos")}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md">
                <div class="ftco-footer-widget mb-4">
                    <router-link :to="{name:'services'}">
                        <h2 class="ftco-heading-2">{{message("servicios/servicios")}}</h2>
                    </router-link>
                    <ul class="list-unstyled">
                        <li>
                            <p>{{message("servicios/mantenimiento")}}</p>
                        </li>
                        <li>
                            <p>{{message("servicios/soporte")}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md">
                <div class="ftco-footer-widget mb-4">
                    <router-link :to="{name:'contact'}">
                        <h2 class="ftco-heading-2">{{message("contacto/contacto")}}</h2>
                    </router-link>
                    <div class="block-23 mb-3">
                        <ul>
                            <li><span class="icon icon-map-marker"></span><span class="text">{{message("contacto/direccion1")}}</span></li>
                            <li><a :href="'tel://'+message('contacto/telefono1')"><span class="icon icon-phone"></span><span class="text">{{message("contacto/telefono1")}}</span></a></li>
                            <li><span class="icon icon-map-marker"></span><span class="text">{{message("contacto/direccion2")}}</span></li>
                            <li><a :href="'tel://'+message('contacto/telefono2')"><span class="icon icon-phone"></span><span class="text">{{message("contacto/telefono2")}}</span></a></li>
                            <li><a :href="'mailto:'+message('contacto/correo')"><span class="icon icon-envelope"></span><span class="text">{{message("contacto/correo")}}</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
import lang from "@/utils/lang";
export default
{
    name: "footer-component",
    data()
    {
        return {
            module_name: "shared",
        }
    },
    methods:
    {
        /**
         * Obtener el mensaje de la clave solicitada
         * @param key string Key del mensaje
         */
        message(key)
        {
            return lang(this.module_name, key);
        }
    }
}
</script>

<style scoped>
.footer-logo {
    display: block;
    width: 188px;
    height: 61px;
}
</style>
