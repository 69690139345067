export default
    {
        productos:
        {
            productos: "Productos",
            calibracion: "Calibración",
            automatizacion: "Automatización",
            instrumentacion: "Instrumentación",
            fabricacion: "Fabricación",
            gama_productos: "Gama de productos",
        },
        servicios:
        {
            servicios: "Servicios",
            mantenimiento: "Mantenimiento",
            soporte: "Soporte",
        },
        contacto:
        {
            contacto: "Contacto",
            preguntas: "Contáctenos",
            telefono: "Teléfono",
            direccion1: "Calle Del Mezquite Lote 5 Mza. 3, Parque Industrial Tehuacán-Miahuatlán, C.P. 75820 Tehuacán, Pue.",
            telefono1: "(+52) 238-688-1031",
            direccion2: "Av. Francisco I. Madero 1000, Ma. de la Piedad, C.P. 96410 Coatzacoalcos, Ver.",
            telefono2: "(+52) 921-163-8785",
            correo: "contacto@conserflow.com",
            nombre:"Nombre",
            correo_electronico:"Correo electrónico",
            asunto:"Asunto",
            mensaje:"Mensaje",
            enviar:"Enviar Mensaje",
        },
        navbar:
        {
            inicio: "Inicio",
            productos: "Productos",
            servicios: "Servicios",
            galeria: "Galería",
            empresa: "Empresa",
            contacto: "Contacto",
        }
    }