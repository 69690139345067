export default
    {
        productos:
        {
            nombre: "Productos",
            descripcion: "Estamos comprometidos a proporcionar servicios y productos que cumplan con los más altos niveles de calidad, realizando nuestras actividades con seguridad y mejora continua. Colaborando a la protección del medio ambiente."
        },
        calibracion:
        {
            calibracion: "CALIBRACIÓN",
            laboratorio: "Laboratorio de Calibración de Flujo",
            descripcion: "Actualmente contamos bajo resguardo con medidores patrón con certificación vigente ante el CENAM, para realizar calibraciones de diferentes dimensiones, de acuerdo a las necesidades del servicio:",
            cmf: "CMFHC2 | CMF200 | CMF025 | CMF100 | CMF300 | CMF050"
        },
        especializacion:
        {
            especializacion: "ESPECIALIZACIÓN",
            medidores_descripcion: "Calibración de medidores de flujo tipo:",
            medidores:
                [
                    "Coriolis",
                    "Magnéticos",
                    "Vortex",
                    "Turbina",
                    "Calibración en banco",
                    "Calibración en sitio bajo proceso",
                    "Calibración para servicios de gas",
                    "Calibración en embarcaciones",
                    "Procura y fabricación de materiales",
                    "Servicio de diagnóstico, configuración y puesta en marcha de medidores de flujo para Micro Motion Rosemount, Yokogawa y Endress+Hauser,Oval, Neptune, Khrone, etc.",
                ],
        },
        automatizacion:
        {
            automatizacion: "AUTOMATIZACIÓN",
            propuestas_descripcion: "Dentro de nuestras propuestas de soluciones tenemos desarrollo y diseño de:",
            propuestas:
                [
                    "Ingeniería para plantas de proceso (básica y de detalle)",
                    "Ingeniería de sistemas contra incendio y de supresión de fuego",
                    "Sistemas de llenado automático, para auto, tanques y carro tanques",
                    "Sistemas de control de temperatura para hornos, calderas y quemadores",
                    "Sistemas de tele con medición",
                    "Ingeniería para el diseño de tanques de proceso y almacenamiento",
                    "Ingeniería eléctrica integral",
                    "Diseño y simulación de procesos",
                    "Obra eléctrica e instrumentación",
                    "Obra electromecánica",
                    "Sistemas de medición de flujo de gas, petróleo y derivados.",
                    "Migraciones de control distribuido",
                    "Diagnóstico de falla de esquemas de control",
                    "Estudios de integridad eléctrica",
                    "Estudios hidráulicos e inspección de integridad mecánica de ductos",
                    "Montaje, instalación de tuberías eléctrica, de instrumentación y accesorios",
                    "Prefabricación y montaje de tuberías de procesos",
                ]
        },
        instrumentacion:
        {
            instrumentacion: "INSTRUMENTACIÓN",
            instrumentos:
                [
                    "Ingeniería básica y de detalles para esquemas de control críticos de sistemas",
                    "Instrumentación de seguridad (SIS)",
                    "Sistemas de gas y fuego",
                    "Ingeniería de control de procesos (Control de Nivel, presión, Flujo y T°)",
                    "Sistemas de Analizadores de oxigeno",
                    "Sistemas de monitoreo de vibraciones",
                    "Montaje y puesta en operación de instrumentación y capacitación ",
                ]
        },
        fabricacion:
        {
            fabricacion: "FABRICACIÓN",
            fabricaciones:
                [
                    "Ingeniería arquitectónica y civil",
                    "Ingeniería mecánica",
                    "Modelado 3D y Maquetas electrónicas",
                ]
        },
        gama_productos:
        {
            gama_productos: "GAMA DE PRODUCTOS",
            productos:
                [
                    "Fabricación de Patín de Medición en Mexitrade",
                    "Servicios de Calibración de medidores de flujos másicos",
                    "Obra civil-mecánica en By Pass Área de Subestación Donají",
                    "Instalación de quemadores (Obra mecánica e instrumentación)",
                    "Mantenimiento a instrumentación y tableros gabinetes de comunicación",
                    "Fabricación de patines de medición y Servicios de Hot Tapping Machine",
                    "Instalación de analizadores de H2/Hidrocarburos y tanques de calibración",
                    "Instalación de Transmisores de vibración CS19420 y repetidores 702 en equipos críticos",
                    "Fabricación de Shelter CEMS, casetas para Sistema analítico y vibración para turbogeneradores, Complejo Petroquímico Morelos",
                ]
        },
    }