export default
    {
        servicios:
        {
            servicios: "SERVICIOS DE INGENIERÍA",
            servicios_descripcion: "Formado por siete disciplinas especializadas que interactúan una con otra proporcionando conocimiento y soporte sólido a las diferentes áreas dentro de la compañía y a nuestros clientes.",
        },
        diagrama: "Conserflow cuenta con la experiencia en desarrollo de proyectos: llave de mano, proyecto EPC, (ENGINEERING, PROCUREMENT AND CONSTRUCTION)",
        mantenimiento:
        {
            mantenimiento: "MANTENIMIENTO",
            mantenimientos:
                [
                    "Migración de sistemas de control distribuido",
                    "Instalación y mantenimiento a sistemas de tierra delta",
                    "Diagnóstico y mantenimiento a Instrumentación: válvulas, instrumentos (flujo, presión, temperatura, analítica) cromatografía, hornos, transmisores, probetas de muestreo para cromatógrafos, sistemas de acondicionamiento de muestra. Marcas: Emerson, Endress + Hauser, Yokogawa, SIEMENS",
                ]
        },
        soporte:
        {
            soporte: "SOPORTE",
            actividades:
                [
                    "Soporte técnico a: Computadores de Flujo Control Wave, ROC/FLOBOSS +S600 Y PLC'S",
                    "Arranque y puesta en operaciones de medidores de flujo tipo coriolis, magnético, vortex y ultrasónicos",
                ],
        }
    }