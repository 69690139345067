let current = "-";

/**
 * Obtener el lenguaje actual
 */
export const getCurrentLanguaje = () =>
{
    let stored = "";
    if (current == "-") // No asigando
    {
        // Obtener del sotage
        stored = localStorage.getItem("lang");
        current = stored == null ? "es" : stored;
    }
    return current;
}

/**
 * Cambiar el idioma actual
 * @param string languaje Nuevo idioma 
 */
export const setCurrentLanguaje = (languaje) =>
{
    current = languaje;
    localStorage.setItem("lang",languaje);
}

