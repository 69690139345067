import shared from "./shared"
import home from "./home"
import productos from "./productos"
import servicios from "./servicios"
import empresa from "./empresa"

export default
    {
        shared,
        home,
        productos,
        servicios,
        empresa,
    }