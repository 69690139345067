export default
    {
        empresa: "EMPRESA",
        vision: "VISIÓN",
        vision_descripcion: "Ser vistos como un referente de soluciones tecnológicas en el área de la industria del proceso, mecánica, eléctrica, civil, de instrumentación, automatización y control manteniendo un alto nivel de calidad y confianza con nuestros clientes.",
        mision: "MISIÓN",
        mision_descripcion: "Ser vistos como un referente de soluciones tecnológicas en el área de la industria del proceso, mecánica, eléctrica, civil, de instrumentación, automatización y control manteniendo un alto nivel de calidad y confianza con nuestros clientes.",
        techado: "Conserflow cuenta con taller techado para la realización de trabajos en banco, así como la herramienta menor y especializada necesaria",
        areas: "Contamos con áreas para la construcción de estructuras y tuberías, fabricación de shelters, pruebas hidrostáticas, mantenimiento mecánico, mantenimiento a válvulas, calibración y laboratorios para pruebas de instrumentación y control.",
        valores_descripcion: "VALORES",
        valores:
            [
                "Disciplina",
                "Respeto",
                "Adaptabilidad",
                "Resolución",
            ]
    }