import {createRouter,createWebHistory} from "vue-router";
import Home from "@/components/pages/Home.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "About" */ "@/components/pages/About.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import(/* webpackChunkName: "Products" */ "@/components/pages/Products.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () => import(/* webpackChunkName: "Services" */ "@/components/pages/Services.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import(/* webpackChunkName: "Gallery" */ "@/components/pages/Gallery.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "About" */ "@/components/pages/About.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import(/* webpackChunkName: "contact" */ "@/components/pages/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() =>
{
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
})

export default router;
