import es from "./es/es";
import {getCurrentLanguaje} from "./current";

const languajes =
{
    es,
};
// Lenguaje actual
const current = getCurrentLanguaje();

const getMessage = (module,path) =>
{
    // Unir el lenguja actual, modulo y la ruta dada
    const key = `${ current }/${ module }/${ path }`;
    // Divide el path por "/"
    const niveles = key.split("/");
    let valor = languajes;
    for (const nivel of niveles)
    {
        // Comprobar que exista la key
        if (!(nivel in valor))
            return `LANG ERRROR. Key not found: ${ key }`;
        // Accede al siguiente nivel del objeto
        valor = valor[nivel];
    }
    return valor;
}

export default getMessage;