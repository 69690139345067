<template>
<!-- Video -->
<video autoplay muted loop class="video-s">
    <source src="videos/banner.mp4" type="video/mp4">
</video>
<!-- Video -->

<!-- About -->
<section class="" data-aos="fade-right">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 heading-section text-center mb-5">
                <span class="subheading">&nbsp;</span>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-md-6">
                <img class="img-fluid" src="images/home/1.webp" alt="">
            </div>
            <div class="col-md-6 wrap-about py-md-5">
                <div class="heading-section mb-5 pl-md-5">
                    <p class="h5 my-3"> {{message("filosofia/info")}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About -->

<!-- Politica -->
<section class="ftco-section ftco-car-details" data-aos="fade-up">
    <div class="container">
        <div class="row">
            <div class="col-md-12 pills">
                <div class="bd-example bd-example-tabs">
                    <div class="d-flex justify-content-center">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-description-tab" data-toggle="pill" href="#pills-description" role="tab" aria-controls="pills-description" aria-expanded="true">
                                    {{message("filosofia/politica")}}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-manufacturer-tab" data-toggle="pill" href="#pills-manufacturer" role="tab" aria-controls="pills-manufacturer" aria-expanded="true">
                                    {{message("filosofia/mision")}}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-review-tab" data-toggle="pill" href="#pills-review" role="tab" aria-controls="pills-review" aria-expanded="true">
                                    {{message("filosofia/vision")}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-description" role="tabpanel" aria-labelledby="pills-description-tab">
                            <p>{{message("filosofia/politica_text")}}</p>
                        </div>
                        <div class="tab-pane fade" id="pills-manufacturer" role="tabpanel" aria-labelledby="pills-manufacturer-tab">
                            <p>{{message("filosofia/mision_text")}}</p>
                        </div>
                        <div class="tab-pane fade" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab">
                            <p> {{message("filosofia/vision_text")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Politica -->

<!-- Certificaciones -->
<section class="ftco-section ftco-car-details" data-aos="fade-left">
    <div class="container">
        <div class="row">
            <div class="col-md-12 pills">
                <div class="d-flex justify-content-center">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <p class="h4">{{message("certificaciones/certificaciones")}}</p>
                    </ul>
                </div>
                <div class="container container_certs d-flex certificaciones px-2 py-2 justify-content-center">
                    <img data-aos="flip-left" @click="openModal('images/home/cert_asme.webp','ASME')" src="images/home/asme.webp" class="img-fluid" alt="ASME">
                    <img data-aos="flip-left" @click="openModal('images/home/cert_9001_2015.webp','ISO 9001-2015')" src="images/home/9001_2015.webp" class="img-fluid" alt="9001_2015">
                    <img data-aos="flip-right" @click="openModal('images/home/cert_14001_2015.webp','ISO 14001-2015')" src="images/home/14001_2015.webp" class="img-fluid" alt="14001_2015">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Clientes -->
<section class="section testimony-section" style="background-image: url(images/home/baner_alianza.webp);">

    <div class="container" data-aos="fade-down">
        <div class="row justify-content-center mb-2">
            <div class="col-md-7 text-center heading-section">
                <h4 class="mb-2 mt-4">{{message("clientes/clientes")}}</h4>
            </div>
            <div class="wrapper">
                <div class="slider">
                    <div class="slide">
                        <img src="images/clientes/emerson.webp" />
                        <img src="images/clientes/pemex.webp" />
                        <img src="images/clientes/unigel.webp" />
                        <img src="images/clientes/transcanada.webp" />
                        <img src="images/clientes/ica.webp" />
                        <img src="images/clientes/pmv.webp" />
                        <img src="images/clientes/etech.webp" />
                        <img src="images/clientes/innophos.webp" />
                        <img src="images/clientes/vopak.webp" />
                    </div>
                    <div class="slide">
                        <img src="images/clientes/emerson.webp" />
                        <img src="images/clientes/pemex.webp" />
                        <img src="images/clientes/unigel.webp" />
                        <img src="images/clientes/transcanada.webp" />
                        <img src="images/clientes/ica.webp" />
                        <img src="images/clientes/pmv.webp" />
                        <img src="images/clientes/etech.webp" />
                        <img src="images/clientes/innophos.webp" />
                        <img src="images/clientes/vopak.webp" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- alianzas -->
    <div class="container py-5" data-aos="fade-up">
        <div class="row justify-content-center mb-2">
            <div class="col-md-7 text-center heading-section">
                <h4 class="my-3">{{message("alianzas/alianzas")}}</h4>
            </div>
            <div class="wrapper">
                <div class="slider">
                    <div class="slide">
                        <img src="images/alianzas/micro_motion.webp" />
                        <img src="images/alianzas/phoenix_contact.webp" />
                        <img src="images/alianzas/wika.webp" />
                        <img src="images/alianzas/expert_engineering.webp" />
                        <img src="images/alianzas/fisher.webp" />
                        <img src="images/alianzas/mc.webp" />
                    </div>
                    <div class="slide">
                        <img src="images/alianzas/micro_motion.webp" />
                        <img src="images/alianzas/phoenix_contact.webp" />
                        <img src="images/alianzas/wika.webp" />
                        <img src="images/alianzas/expert_engineering.webp" />
                        <img src="images/alianzas/fisher.webp" />
                        <img src="images/alianzas/mc.webp" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- Clientes -->

<div id="myModal" ref="myModal" class="modal">
    <span class="close" @click="closeModal">&times;</span>
    <img class="modal-content" id="imgModal" ref="imgModal">
    <div id="modal_desc" ref="modal_desc"></div>
</div>
<!-- Certificaciones -->
</template>

<script>
import lang from "@/utils/lang";
export default
{
    name: "home-page",
    data()
    {
        return {
            module_name: "home",
        }
    },
    methods:
    {
        /**
         * Obtener el mensaje de la clave solicitada
         * @param key string Key del mensaje
         */
        message(key)
        {
            return lang(this.module_name, key);
        },

        /*
         * Abrir el modal con la imagen seleccionada
         */
        openModal(src, desc)
        {
            this.$refs.myModal.style.display = "block";
            this.$refs.imgModal.src = src;
            this.$refs.modal_desc.innerHTML = desc;
        },

        /**
         * Cerrar modal de la imagen
         */
        closeModal()
        {
            this.$refs.myModal.style.display = "none";
        }
    }
}
</script>

<style scoped>
.video-s1 {
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.certificaciones img {
    width: 250px;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6;
}

.certificaciones img:hover {
    padding: 0.25rem;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
}

.container_certs {
    min-width: 80vw;
    overflow-x: scroll;
}

.modal {
    display: none;
    position: fixed;
    z-index: 3;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
    margin: auto;
    display: block;
    width: auto;
}

#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

.modal-content,
#caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}
</style>
