<template>
<nav class="navbar navbar-c-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container">
        <a class="navbar-brand" href="/" style="background-image: url('images/conserflow.webp'); display:block;width:188px;height:61px;"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon icon-menu" style="font-size:2rem"></span>
        </button>
        <div class="collapse navbar-collapse" id="ftco-nav">
            <ul class="navbar-nav ml-auto">
                <router-link :to="{name:'home'}" class="nav-link" active-class="active2">
                    <li class="nav-item active">{{message("navbar/inicio")}}</li>
                </router-link>
                <router-link :to="{name:'products'}" class="nav-link" active-class="active2">
                    <li class="nav-item active">{{message("navbar/productos")}}</li>
                </router-link>
                <router-link :to="{name:'services'}" class="nav-link" active-class="active2">
                    <li class="nav-item active">{{message("navbar/servicios")}}</li>
                </router-link>
                <!-- <router-link :to="{name:'gallery'}" class="nav-link" active-class="active2">
                    <li class="nav-item">{{message("navbar/galeria")}}</li>
                </router-link> -->
                <router-link :to="{name:'about'}" class="nav-link" active-class="active2">
                    <li class="nav-item">{{message("navbar/empresa")}}</li>
                </router-link>
                <router-link :to="{name:'contact'}" class="nav-link" active-class="active2">
                    <li class="nav-item">{{message("navbar/contacto")}}</li>
                </router-link>
            </ul>
            <!-- <ul id="portal-languageselector" class="language-c">
                <li class="language-eu">
                    <a class="lang" href="" @click.prevent="cambiarIdioma('en')">English</a>
                </li>
                <li class="language-eu">
                    <a class="" href="" @click.prevent="cambiarIdioma('es')">Español</a>
                </li>
            </ul> -->
        </div>
    </div>
</nav>
</template>

<script>
import lang from "@/utils/lang";
export default
{
    name: "nabvar-component",
    data()
    {
        return {
            module_name: "shared",
        }
    },
    methods:
    {
        /**
         * Obtener el mensaje de la clave solicitada
         * @param key string Key del mensaje
         */
        message(key)
        {
            return lang(this.module_name, key);
        },

        cambiarIdioma(lang)
        {
            this.$emit("changeLanguage", lang);
        },
    }
}
</script>

<style scoped>
.active2 li {
    color: #117a8b;
    font-weight: 400;
}
</style>
